"use client"
import { getIcon } from '@/lib/get-icon'
import Button from '@/app/ui/button'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import homeIcons from '../icons/home-icons'
import useYscroll from '../common/functions'

// import { useRecentBlogQuery } from '@/framework/rest/blogs/blog.query'

function Insights({ insight, miniTitle }: any) {
    const { shown } = useYscroll({})
    // const { data } = useRecentBlogQuery()

    return (
        <>
            {shown &&
                <div>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-8 sm:gap-0">
                        {insight?.map((res: any, index: any) =>
                            <div key={index + 'insights'} className='col-span-1 mx-[10px] sm:mx-2'>
                                <div className="shadow mb-5" >
                                    <Image className='w-full h-[320px] sm:h-[170px] object-cover' src={res?.image} height={170} width={180} alt="Invenzo Blog" />
                                    {/* <Link className='text-[#4d5972] text-1xl lg:text-2xl mt-2 mb-1 font-bold l-spacing-0 heading ' href={``}>  {res.title} </Link> */}
                                </div>

                                <div className='flex items-center gap-3 mb-5'>
                                    <div className="w-[63.23px] h-[33.05px] px-[12.61px] py-[2.52px] bg-orange-100 rounded-[1px] flex-col justify-center items-center inline-flex text-fuchsia-950 text-[15.14px] xs:text-sm font-bold uppercase leading-7">{res?.type}</div>
                                    <span className="text-neutral-700 text-base sm:text-sm xs:text-sm font-medium uppercase leading-relaxed tracking-wider">{res?.created_at}</span>
                                </div>
                                <div className="opacity-80 text-neutral-900 text-[31.05px] sm:text-[16px] sm:leading-normal xs:text-xl xs:mb-3 font-medium leading-[37.84px]"><Link href={res?.page_link}>{res?.heading}</Link></div>
                            </div>
                        )
                        }
                    </div>
                    <div className='flex justify-center mt-16 mb-8'>
                        <Link href="/blog" className='mx-auto '>
                            <Button variant="black">View More Insights {getIcon({ iconList: homeIcons, iconName: 'ButtonArrowRight' })}</Button>
                        </Link>
                    </div>
                </div>
            }
        </>
    )
}

export default Insights