"use client"
import React, { useState } from 'react'
import Container from '@/ui/container'
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@/ui/carousel/carousel';
import { getIcon } from '@/lib/get-icon'
import Link from 'next/link'
import Button from '@/app/ui/button'
import bannerIcons from '../icons/banner-icons'
import commonIcons from '../icons/common-icons'
import YouTubeVideo from '../modal/youtubeVideo'
import RequestDemo from '../common/request-demo'
import BannerImgs from './banner-imgs'

//import ReactPlayer from 'react-player';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import("react-player"), {
  loading: () => <p>Loading...</p>,
});

import data from "@/data/banner-data.json"

function IndexBanner({ homeConent }: any) {

  const bannerContent = data;
    
  const staticElem = {
    buttonOne: "Book a Demo",
    buttonTwo: "Explore Invenzo",
    square: '#26CEC7',
    astrich: '#C84E89',
  };

  const joinProps = { bannerContent, staticElem }
  const [showModal, setShowModal] = useState(false);
  let [isRequestOpen, setIsRequestOpen] = useState(false);
  function closeRequestModal() {
    setIsRequestOpen(false);
  }
  function openRequestModal() {
    setIsRequestOpen(true);
  }


  return (
    <div className="index-banner xs:bg-none bg-[url('/images/banner-bg.webp')] bg-left-top bg-cover bg-no-repeat max-w-full pt-8 xs:pt-4 pb-5 w-screen">
      <div className='hidden xs:block absolute w-full overflow-hidden top-0 left-0'>
        <Image className='w-full max-w-none' src={'/images/mobile-grid.webp'} alt='banner grid' width={410} height={80} />
      </div>
      <Container className="max-w-full px-10 xs:px-5 sm:px-5">
        <Carousel
        //autoplay={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            reverseDirection: false,
          }}
          loop={true}
          className={`mx-0 fullWidth pagination-center col-span-12 self-end index-carousel`}
          buttonClassName={""}
          spaceBetween={32}
          navigation={{
            prevEl: ".swiper_prev_banner",
            nextEl: ".swiper_next_banner",
          }}
          buttonPosition={"outside"}
          centeredSlides={false}
          pagination={true}
        //   breakpoints={breakpoints}
        >
          {bannerContent?.map((item: any, index: number) => (
            <SwiperSlide key={index + 'homePageBananer'} className='bg-transparent'>
              <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
                <div className="col-span-1 items-start xs:order-2 sm:order-none xs:mb-10">
                  <div className='flex flex-col mb-[84px] lg:mb-10 xs:mb-5'>
                    <h2 className={"text-[64px] leading-[71px] sm:leading-tight xs:text-[31px] sm:text-[32px] lg:leading-[71px] xs:leading-[1] lg:mb-10 xs:mb-5 font-bold text-center md:text-left"}
                      dangerouslySetInnerHTML={{ __html: item?.title }}
                    > 
                    </h2>
                    <div className="text-[#3A3A3A] text-[21px] xs:text-base sm:text-base sm:py-5 font-medium xs:text-center" dangerouslySetInnerHTML={{ __html: item?.subTitle }} />

                    <div className='relative flex xs:flex-wrap gap-10 xs:gap-5 xs:mt-5 lg:mt-10 xl:mt-20 xs:justify-center'>
                      <Link prefetch={false} href="" type="button" className="">
                        <Button className='sm:text-base sm:px-4 sm:gap-2 sm:whitespace-nowrap' onClick={openRequestModal}>{item?.buttonOne} {getIcon({ iconList: bannerIcons, iconName: "BookingIcon" })}</Button>
                      </Link>
                      <Link prefetch={false} href="/contact-us" type="button" className="">
                        <Button className='sm:text-base sm:px-4 sm:gap-2 sm:whitespace-nowrap' variant='secondary'>{item?.buttonTwo}  {getIcon({ iconList: bannerIcons, iconName: "ExploreIcon" })}</Button>
                      </Link>
                      <div className=' xs:hidden flex-none absolute right-0 lg:-bottom-4 lg:-right-0 xl:right-0 sm:top-12'>{getIcon({ iconList: commonIcons, iconName: "RightArkArrowIcon", fill: "#FFB153", className: "relative self-end right-10 sm:w-[100px] sm:h-[100px]" })}</div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 relative xs:min-h-[250]">
                  {/* <Image onClick={() => setShowModal(true)} src={"/images/play-icon.webp"} alt='Invenzo paly' width={50} height={50} className='play-icon absolute xs:w-10 sm:w-10 xs:h-10 top-[4rem] xs:top-9 sm:top-[41px] lg:top-[5.5rem] xl:top-[6.25rem] left-12 sm:left-[3.8rem] lg:left-[7.5rem] xl:left-[13rem] 2xl:left-[14.5rem] z-10 cursor-pointer' /> */}
                  <div onClick={() => setShowModal(true)}  className='play-icon bg-transparent  absolute left-[calc(100%-92.5%)] 2xl:left-[calc(100%-83.5%)] xl:left-[calc(100%-89.3%)] top-[8%] sm:top-[6%] w-[calc(100%-71%)] xl:w-[calc(100%-73%)] 2xl:w-[calc(100%-77%)] h-[50%] sm:h-[35%] z-10 cursor-pointer' /> 
                    <BannerImgs item={item?.image}/>                 
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      </Container>

      <YouTubeVideo isVisible={showModal} onClose={() => setShowModal(false)} >
        <ReactPlayer width="100%" height="100%" playing controls url='https://youtu.be/Qb8eqY8w43A' />
      </YouTubeVideo>

      <RequestDemo isRequestOpen={isRequestOpen} closeRequestModal={closeRequestModal} />
    </div>
  )
}

export default IndexBanner
